import Big, { BigSource } from "big.js";
import { ObjectId, ObjectIdLike } from "bson";

import { Offer } from "~served/types/db";
import {
	DISCOUNT_TYPE,
	ORDER_ITEM_STATUS,
	OrderItemSubtotalAddOns,
	OriginalPriceAddons,
} from "~served/types/gql";

import {
	getAppliedOfferByItems,
	getAppliedOfferByReceipt,
	isReceiptOffer,
} from "../getAppliedOffer";
import { DiscountInput, getDiscountAmount } from "../getDiscountAmount";
import { bigMath, isUndefinedOrNull } from "../misc";

export type ObjectIdField = string | ObjectIdLike | ObjectId;
export type MongoDocument = Record<string, any>;

interface PaymentType {
	payment_type: string;
	code: string;
	amount: number;
}
interface OrderLike {
	items: OrderItemLike[];
	cancelled_items?: OrderItemLike[];
	[key: string]: any;
}
interface OrderItemLike {
	_id: ObjectIdField;
	item_id: string;
	title: string;
	prep_time: number;
	type: string;
	no_vat: boolean;
	no_service_charge: boolean;
	original_price: number;
	original_price_addons: OriginalPriceAddons;
	listed_price: number;
	quantity: number;
	extra_quantity: number;
	options: OrderItemOptionLike[];
	subtotal_addons?: OrderItemSubtotalAddOns;
	status?: string;
	[key: string]: any;
}

interface OrderItemOptionLike {
	_id: ObjectIdField;
	option_id: string;
	title: string;
	original_price: number;
	original_price_addons: OriginalPriceAddons;
	listed_price: number;
	quantity: number;
	subtotal_addons?: OrderItemSubtotalAddOns;
	[key: string]: any;
}

type VenueLike = {
	vat: number;
	is_vat_buried: boolean;
	service_charge: number;
	is_service_charge_buried: boolean;
} & MongoDocument;

const BASE_SUBTOTAL_ADDONS: OrderItemSubtotalAddOns = {
	offer: { amount: 0 },
	discount: {
		is_divided: false,
		type: DISCOUNT_TYPE.percentage,
		value: 0,
		amount: 0,
	},
	vat: { is_included: false, percentage: 0, amount: 0 },
	service_charge: { is_included: false, percentage: 0, amount: 0 },
	adjustment: { amount: 0 },
};

const applyAdjustmentToItem = <T>({
	item,
	adjustmentAmount,
}: {
	item: OrderItemLike & T;
	adjustmentAmount: number;
}) => {
	const orderItem = $getItemMetadata(item);
	const subtotal_addons = {
		...BASE_SUBTOTAL_ADDONS,
		...orderItem.subtotal_addons,
		adjustment: {
			amount: adjustmentAmount,
		},
	};

	let orderItemOptions = orderItem.options.map((orderItemOption) =>
		$getOptionMetadata(orderItemOption, orderItem),
	);
	orderItemOptions = orderItemOptions.map((orderItemOption) => {
		const optionPriceRatioToItem = bigMath.div(
			orderItemOption.subtotal,
			orderItem.subtotal,
		);

		return {
			...orderItemOption,
			subtotal_addons: {
				...orderItemOption.subtotal_addons,
				adjustment: {
					amount: bigMath.mul(optionPriceRatioToItem, adjustmentAmount),
				},
			},
		};
	});

	return {
		...orderItem,
		subtotal_addons,
		options: orderItemOptions,
	};
};

const applyAdjustmentToOrder = <T>({
	order,
	adjustmentAmount,
}: {
	order: OrderLike & T;
	adjustmentAmount: number;
}) => {
	let orderItems = order.items.map($getItemMetadata).map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) =>
			$getOptionMetadata(orderItemOption, orderItem),
		),
	}));
	const subtotal = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);

	let remainingAmountForDivision = adjustmentAmount;
	orderItems = orderItems
		.sort((a, b) => a.subtotal - b.subtotal)
		.map((orderItem, i) => {
			const isThereAmountToDivide = remainingAmountForDivision !== 0;
			const isThisLastItem = i === orderItems.length - 1;

			if (isThisLastItem) {
				const subtotal_addons = {
					...orderItem.subtotal_addons,
					adjustment: {
						amount: isThereAmountToDivide ? remainingAmountForDivision : 0,
					},
				};

				return { ...orderItem, subtotal_addons };
			}

			const itemPriceRatioToOrder = bigMath.div(orderItem.subtotal, subtotal);
			let dividedAdjustmentAmountForItem = isThereAmountToDivide
				? bigMath.mul(adjustmentAmount, itemPriceRatioToOrder)
				: 0;
			dividedAdjustmentAmountForItem =
				Math.abs(dividedAdjustmentAmountForItem) > orderItem.subtotal
					? orderItem.subtotal
					: dividedAdjustmentAmountForItem;

			const subtotal_addons = {
				...orderItem.subtotal_addons,
				adjustment: {
					amount: dividedAdjustmentAmountForItem,
				},
			};

			remainingAmountForDivision = bigMath.sub(
				remainingAmountForDivision,
				dividedAdjustmentAmountForItem,
			);

			return { ...orderItem, subtotal_addons };
		});

	// options only stats
	orderItems = orderItems.map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) => {
			const optionPriceRatioToItem = bigMath.div(
				orderItemOption.subtotal,
				orderItem.subtotal,
			);

			return {
				...orderItemOption,
				subtotal_addons: {
					...orderItemOption.subtotal_addons,
					adjustment: {
						amount: bigMath.mul(
							optionPriceRatioToItem,
							orderItem.subtotal_addons.adjustment.amount,
						),
					},
				},
			};
		}),
	}));

	const adjustment_amount = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal_addons.adjustment.amount),
		0,
	);

	return calculateOrder({
		...order,
		items: orderItems,
		subtotal,
		adjustment_amount,
	}).$order;
};

const applyAdjustmentToOrderGroup = <T>({
	orders: allOrders,
	adjustmentAmount,
	skipPaidCheck = false,
}: {
	orders: (OrderLike & T)[];
	adjustmentAmount: number;
	skipPaidCheck?: boolean;
}) => {
	const unpaidOrders = allOrders.filter((o) =>
		skipPaidCheck ? true : $isOrderUnpaid(o),
	);
	const orderGroupSubTotal = unpaidOrders.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);
	let remainingAmountForDivision = adjustmentAmount || 0;
	const updatedOrders = unpaidOrders
		.sort((a, b) => a.subtotal - b.subtotal)
		.map((order, i) => {
			const isThereAmountToDivide = remainingAmountForDivision !== 0;
			const isThisLastItem = i === unpaidOrders.length - 1;

			if (isThisLastItem) {
				return applyAdjustmentToOrder({
					order,
					adjustmentAmount: isThereAmountToDivide
						? remainingAmountForDivision
						: 0,
				});
			}

			const orderPriceRatioToGroup = bigMath.div(
				order.subtotal,
				orderGroupSubTotal,
			);
			let dividedAdjustmentAmountForOrder = isThereAmountToDivide
				? bigMath.mul(adjustmentAmount, orderPriceRatioToGroup)
				: 0;
			dividedAdjustmentAmountForOrder =
				Math.abs(dividedAdjustmentAmountForOrder) > order.subtotal
					? order.subtotal
					: dividedAdjustmentAmountForOrder;

			remainingAmountForDivision = bigMath.sub(
				remainingAmountForDivision,
				dividedAdjustmentAmountForOrder,
			);

			return applyAdjustmentToOrder({
				order,
				adjustmentAmount: dividedAdjustmentAmountForOrder,
			});
		});

	return updatedOrders;
};

const applyDiscountToItem = <T>({
	item,
	discount,
}: {
	item: OrderItemLike & T;
	discount: DiscountInput;
}) => {
	const orderItem = $getItemMetadata(item);
	const { discountAmount } = getDiscountAmount(orderItem.subtotal, discount);
	const subtotal_addons = {
		...orderItem.subtotal_addons,
		discount: {
			...discount,
			is_divided: false,
			amount: discountAmount,
		},
	};

	let orderItemOptions = orderItem.options.map((orderItemOption) =>
		$getOptionMetadata(orderItemOption, orderItem),
	);
	orderItemOptions = orderItemOptions.map((orderItemOption) => {
		const optionPriceRatioToItem = bigMath.div(
			orderItemOption.subtotal,
			orderItem.subtotal,
		);

		return {
			...orderItemOption,
			subtotal_addons: {
				...orderItemOption.subtotal_addons,
				discount: {
					...discount,
					is_divided: true,
					amount: bigMath.mul(optionPriceRatioToItem, discountAmount),
				},
			},
		};
	});

	return {
		...orderItem,
		subtotal_addons,
		options: orderItemOptions,
	};
};

const applyDiscountToOrder = <T>({
	order,
	discount,
	dividedAmountFromGroup,
}: {
	order: OrderLike & T;
	discount: DiscountInput;
	dividedAmountFromGroup?: number;
}) => {
	let orderItems = order.items.map($getItemMetadata).map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) =>
			$getOptionMetadata(orderItemOption, orderItem),
		),
	}));
	const subtotal = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);

	if (discount?.type === DISCOUNT_TYPE.percentage) {
		orderItems = orderItems.map((orderItem) => {
			const { discountAmount } = getDiscountAmount(
				orderItem.subtotal,
				discount,
			);
			const subtotal_addons = {
				...orderItem.subtotal_addons,
				discount: {
					...discount,
					is_divided: false,
					amount: discountAmount,
				},
			};

			return { ...orderItem, subtotal_addons };
		});
	}

	if (discount?.type === DISCOUNT_TYPE.amount) {
		const { discountAmount } = dividedAmountFromGroup
			? { discountAmount: dividedAmountFromGroup }
			: getDiscountAmount(subtotal, discount);

		let remainingAmountForDivision = discountAmount;
		orderItems = orderItems
			.sort((a, b) => a.subtotal - b.subtotal)
			.map((orderItem, i) => {
				const isThereAmountToDivide = remainingAmountForDivision > 0;
				const isThisLastItem = i === orderItems.length - 1;

				if (isThisLastItem) {
					const subtotal_addons = {
						...orderItem.subtotal_addons,
						discount: {
							...discount,
							is_divided: true,
							amount: isThereAmountToDivide ? remainingAmountForDivision : 0,
						},
					};

					return { ...orderItem, subtotal_addons };
				}

				const itemPriceRatioToOrder = bigMath.div(orderItem.subtotal, subtotal);
				let dividedDiscountAmountForItem = isThereAmountToDivide
					? bigMath.mul(discountAmount, itemPriceRatioToOrder)
					: 0;
				dividedDiscountAmountForItem =
					dividedDiscountAmountForItem > (orderItem.subtotal || 0)
						? orderItem.subtotal || 0
						: dividedDiscountAmountForItem;

				const subtotal_addons = {
					...orderItem.subtotal_addons,
					discount: {
						...discount,
						is_divided: true,
						amount: dividedDiscountAmountForItem,
					},
				};

				remainingAmountForDivision = bigMath.sub(
					remainingAmountForDivision,
					dividedDiscountAmountForItem,
				);

				return { ...orderItem, subtotal_addons };
			});
	}

	// options only stats
	orderItems = orderItems.map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) => {
			const optionPriceRatioToItem = bigMath.div(
				orderItemOption.subtotal,
				orderItem.subtotal,
			);

			return {
				...orderItemOption,
				subtotal_addons: {
					...orderItemOption.subtotal_addons,
					discount: {
						...discount,
						is_divided: true,
						amount: bigMath.mul(
							optionPriceRatioToItem,
							orderItem.subtotal_addons.discount.amount,
						),
					},
				},
			};
		}),
	}));

	const discount_amount = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal_addons.discount.amount || 0),
		0,
	);

	return calculateOrder({
		...order,
		items: orderItems,
		subtotal,
		discount_amount,
	}).$order;
};

const applyDiscountToOrderGroup = <T>({
	orders: allOrders,
	discount,
	skipPaidCheck = false,
}: {
	orders: (OrderLike & T)[];
	discount: DiscountInput;
	skipPaidCheck?: boolean;
}) => {
	const unpaidOrders = allOrders.filter((o) =>
		skipPaidCheck ? true : $isOrderUnpaid(o),
	);
	let updatedOrders: OrderLike[] = [];

	if (discount?.type === DISCOUNT_TYPE.percentage) {
		updatedOrders = unpaidOrders.map((order) =>
			applyDiscountToOrder({ order, discount }),
		);
	}

	if (discount?.type === DISCOUNT_TYPE.amount) {
		const orderGroupSubTotal = unpaidOrders.reduce(
			(pre, cur) => bigMath.add(pre, cur.subtotal),
			0,
		);
		const { discountAmount } = getDiscountAmount(orderGroupSubTotal, discount);

		let remainingAmountForDivision = discountAmount;
		updatedOrders = unpaidOrders
			.sort((a, b) => a.subtotal - b.subtotal)
			.map((order, i) => {
				const isThereAmountToDivide = remainingAmountForDivision > 0;
				const isThisLastItem = i === unpaidOrders.length - 1;

				if (isThisLastItem) {
					return applyDiscountToOrder({
						order,
						discount,
						dividedAmountFromGroup: isThereAmountToDivide
							? remainingAmountForDivision
							: 0,
					});
				}

				const orderPriceRatioToGroup = bigMath.div(
					order.subtotal,
					orderGroupSubTotal,
				);
				const dividedDiscountAmountForOrder = isThereAmountToDivide
					? bigMath.mul(discountAmount, orderPriceRatioToGroup)
					: 0;

				remainingAmountForDivision = bigMath.sub(
					remainingAmountForDivision,
					dividedDiscountAmountForOrder,
				);

				return applyDiscountToOrder({
					order,
					discount,
					dividedAmountFromGroup: dividedDiscountAmountForOrder,
				});
			});
	}

	return updatedOrders;
};

const applyNonReceiptOfferToOrder = <TOrder, TOffer>({
	order,
	offer,
}: {
	order: OrderLike & TOrder;
	offer: MongoDocument & TOffer;
}) => {
	let orderItems = order.items.map($getItemMetadata).map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) =>
			$getOptionMetadata(orderItemOption, orderItem),
		),
	}));
	const subtotal = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);

	const { eligibleItems } = getAppliedOfferByItems(orderItems, offer);

	orderItems = orderItems.map((orderItem) => ({
		...orderItem,
		subtotal_addons: {
			...orderItem.subtotal_addons,
			offer: {
				amount: eligibleItems[orderItem._id.toString()] || 0,
				metadata: eligibleItems[orderItem._id.toString()] ? offer : undefined,
			},
		},
	}));

	// options only stats
	orderItems = orderItems.map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) => {
			const itemEligibleAmount = eligibleItems[orderItem._id.toString()] || 0;
			const optionPriceRatioToItem = bigMath.div(
				orderItemOption.subtotal,
				orderItem.subtotal,
			);
			const optionEligibleAmount = bigMath.mul(
				optionPriceRatioToItem,
				itemEligibleAmount,
			);

			return {
				...orderItemOption,
				subtotal_addons: {
					...orderItemOption.subtotal_addons,
					offer: {
						amount: optionEligibleAmount,
						metadata: optionEligibleAmount ? offer : undefined,
					},
				},
			};
		}),
	}));

	const offer_amount = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal_addons.offer.amount),
		0,
	);

	return calculateOrder({ ...order, items: orderItems, subtotal, offer_amount })
		.$order;
};

const applyNonReceiptOfferToOrderGroup = <TOrder, TOffer>({
	orders,
	offer,
	skipPaidCheck = false,
}: {
	orders: (OrderLike & TOrder)[];
	offer: MongoDocument & TOffer;
	skipPaidCheck?: boolean;
}) => {
	return orders
		.filter((o) => (skipPaidCheck ? true : $isOrderUnpaid(o)))
		.map((order) => applyNonReceiptOfferToOrder({ order, offer }));
};

const applyReceiptOfferToOrder = <TOrder, TOffer>({
	order,
	offer,
	dividedAmountFromGroup,
}: {
	order: OrderLike & TOrder;
	offer: MongoDocument & TOffer;
	dividedAmountFromGroup?: number;
}) => {
	let orderItems = order.items.map($getItemMetadata).map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) =>
			$getOptionMetadata(orderItemOption, orderItem),
		),
	}));
	const subtotal = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);

	const { offerAmount } = dividedAmountFromGroup
		? { offerAmount: dividedAmountFromGroup }
		: getAppliedOfferByReceipt(subtotal, offer);

	let remainingAmountForDivision = offerAmount;
	orderItems = orderItems
		.sort((a, b) => a.subtotal - b.subtotal)
		.map((orderItem, i) => {
			const isThereAmountToDivide = remainingAmountForDivision > 0;
			const isThisLastItem = i === orderItems.length - 1;

			if (isThisLastItem) {
				const subtotal_addons = {
					...orderItem.subtotal_addons,
					offer: {
						amount: isThereAmountToDivide ? remainingAmountForDivision : 0,
						metadata: isThereAmountToDivide ? offer : undefined,
					},
				};

				return { ...orderItem, subtotal_addons };
			}

			const itemPriceRatioToOrder = bigMath.div(orderItem.subtotal, subtotal);
			let dividedOfferAmountForItem = isThereAmountToDivide
				? bigMath.mul(offerAmount, itemPriceRatioToOrder)
				: 0;
			dividedOfferAmountForItem =
				dividedOfferAmountForItem > orderItem.subtotal
					? orderItem.subtotal
					: dividedOfferAmountForItem;

			const subtotal_addons = {
				...orderItem.subtotal_addons,
				offer: {
					amount: dividedOfferAmountForItem,
					metadata: dividedOfferAmountForItem ? offer : undefined,
				},
			};

			remainingAmountForDivision = bigMath.sub(
				remainingAmountForDivision,
				dividedOfferAmountForItem,
			);

			return { ...orderItem, subtotal_addons };
		});

	// options only stats
	orderItems = orderItems.map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) => {
			const optionPriceRatioToItem = bigMath.div(
				orderItemOption.subtotal,
				orderItem.subtotal,
			);
			const dividedOfferAmountForItem = bigMath.mul(
				optionPriceRatioToItem,
				orderItem.subtotal_addons.offer.amount,
			);

			return {
				...orderItemOption,
				subtotal_addons: {
					...orderItemOption.subtotal_addons,
					offer: {
						amount: dividedOfferAmountForItem,
						metadata: dividedOfferAmountForItem ? offer : undefined,
					},
				},
			};
		}),
	}));

	const offer_amount = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal_addons.offer.amount),
		0,
	);

	return calculateOrder({ ...order, items: orderItems, subtotal, offer_amount })
		.$order;
};

const applyReceiptOfferToOrderGroup = <TOrder, TOffer>({
	orders: allOrders,
	offer,
	skipPaidCheck = false,
}: {
	orders: (OrderLike & TOrder)[];
	offer: MongoDocument & TOffer;
	skipPaidCheck?: boolean;
}) => {
	const unpaidOrders = allOrders.filter((o) =>
		skipPaidCheck ? true : $isOrderUnpaid(o),
	);
	const orderGroupSubTotal = unpaidOrders.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);
	const { offerAmount } = getAppliedOfferByReceipt(orderGroupSubTotal, offer);

	let remainingAmountForDivision = offerAmount;
	const updatedOrders = unpaidOrders
		.sort((a, b) => (a.subtotal || 0) - (b.subtotal || 0))
		.map((order, i) => {
			const isThereAmountToDivide = remainingAmountForDivision > 0;
			const isThisLastItem = i === unpaidOrders.length - 1;

			if (isThisLastItem) {
				return applyReceiptOfferToOrder({
					order,
					offer,
					dividedAmountFromGroup: isThereAmountToDivide
						? remainingAmountForDivision
						: 0,
				});
			}

			const orderPriceRatioToGroup = bigMath.div(
				order.subtotal || 0,
				orderGroupSubTotal,
			);
			const dividedOfferAmountForOrder = isThereAmountToDivide
				? bigMath.mul(offerAmount, orderPriceRatioToGroup)
				: 0;

			remainingAmountForDivision = bigMath.sub(
				remainingAmountForDivision,
				dividedOfferAmountForOrder,
			);

			return applyReceiptOfferToOrder({
				order,
				offer,
				dividedAmountFromGroup: dividedOfferAmountForOrder,
			});
		});

	return updatedOrders;
};

const applyServiceChargeToOrder = <T>({
	order,
	serviceCharge,
}: {
	order: OrderLike & T;
	serviceCharge: number;
}) => {
	let orderItems = order.items.map($getItemMetadata).map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) =>
			$getOptionMetadata(orderItemOption, orderItem),
		),
	}));
	const subtotal = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);

	orderItems = orderItems.map((orderItem) => {
		const isIncluded =
			!!orderItem.original_price_addons.service_charge.percentage &&
			!!orderItem.original_price_addons.service_charge.amount;
		const serviceChargePercentage = isIncluded
			? orderItem.original_price_addons.service_charge.percentage
			: orderItem.no_service_charge
				? 0
				: serviceCharge;

		const grossSales = bigMath.sub(
			orderItem.subtotal,
			orderItem.subtotal_addons.offer.amount,
			orderItem.subtotal_addons.discount.amount,
		);
		const addedVatScRate = bigMath.add(
			orderItem.original_price_addons.vat.percentage,
			serviceChargePercentage,
		);
		const serviceChargeAmount = new Big(
			new Big(
				new Big(grossSales).sub(
					new Big(grossSales).div(new Big(1).add(addedVatScRate)),
				),
			).mul(serviceChargePercentage),
		)
			.div(addedVatScRate || 1)
			.round(2)
			.toNumber();

		const subtotal_addons = {
			...orderItem.subtotal_addons,
			service_charge: {
				is_included: isIncluded,
				percentage: serviceChargePercentage,
				amount: serviceChargeAmount,
			},
		};

		return { ...orderItem, subtotal_addons };
	});

	// options only stats
	orderItems = orderItems.map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) => {
			const isIncluded =
				!!orderItemOption.original_price_addons.service_charge.percentage &&
				!!orderItemOption.original_price_addons.service_charge.amount;
			const serviceChargePercentage = isIncluded
				? orderItemOption.original_price_addons.service_charge.percentage
				: orderItem.no_service_charge
					? 0
					: serviceCharge;

			const grossSales = bigMath.sub(
				orderItemOption.subtotal,
				orderItemOption.subtotal_addons.offer.amount,
				orderItemOption.subtotal_addons.discount.amount,
			);
			const addedVatScRate = bigMath.add(
				orderItemOption.original_price_addons.vat.percentage,
				serviceChargePercentage,
			);
			const serviceChargeAmount = new Big(
				new Big(
					new Big(grossSales).sub(
						new Big(grossSales).div(new Big(1).add(addedVatScRate)),
					),
				).mul(serviceChargePercentage),
			)
				.div(addedVatScRate || 1)
				.round(2)
				.toNumber();

			return {
				...orderItemOption,
				subtotal_addons: {
					...orderItemOption.subtotal_addons,
					service_charge: {
						is_included: isIncluded,
						percentage: serviceChargePercentage,
						amount: serviceChargeAmount,
					},
				},
			};
		}),
	}));

	const service_charge_amount = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal_addons.service_charge.amount),
		0,
	);

	return calculateOrder({
		...order,
		items: orderItems,
		subtotal,
		service_charge_amount,
	}).$order;
};

const applyServiceChargeToOrderGroup = <T>({
	orders,
	serviceCharge,
	skipPaidCheck = false,
}: {
	orders: (OrderLike & T)[];
	serviceCharge: number;
	skipPaidCheck?: boolean;
}) => {
	return orders
		.filter((o) => (skipPaidCheck ? true : $isOrderUnpaid(o)))
		.map((order) => applyServiceChargeToOrder({ order, serviceCharge }));
};

const applyVatToOrder = <T>({
	order,
	vat,
}: {
	order: OrderLike & T;
	vat: number;
}) => {
	let orderItems = order.items.map($getItemMetadata).map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) =>
			$getOptionMetadata(orderItemOption, orderItem),
		),
	}));
	const subtotal = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);

	orderItems = orderItems.map((orderItem) => {
		const isIncluded =
			!!orderItem.original_price_addons.vat.percentage &&
			!!orderItem.original_price_addons.vat.amount;
		const vatPercentage = isIncluded
			? orderItem.original_price_addons.vat.percentage
			: orderItem.no_vat
				? 0
				: vat;

		const grossSales = bigMath.sub(
			orderItem.subtotal,
			orderItem.subtotal_addons.offer.amount,
			orderItem.subtotal_addons.discount.amount,
		);
		const addedVatScRate = bigMath.add(
			vatPercentage,
			orderItem.original_price_addons.service_charge.percentage,
		);
		const vatAmount = new Big(
			new Big(
				new Big(grossSales).sub(
					new Big(grossSales).div(new Big(1).add(addedVatScRate)),
				),
			).mul(vatPercentage),
		)
			.div(addedVatScRate || 1)
			.round(2)
			.toNumber();

		const subtotal_addons = {
			...orderItem.subtotal_addons,
			vat: {
				is_included: isIncluded,
				percentage: vatPercentage,
				amount: vatAmount,
			},
		};

		return { ...orderItem, subtotal_addons };
	});

	// options only stats
	orderItems = orderItems.map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) => {
			const isIncluded =
				!!orderItemOption.original_price_addons.vat.percentage &&
				!!orderItemOption.original_price_addons.vat.amount;
			const vatPercentage = isIncluded
				? orderItemOption.original_price_addons.vat.percentage
				: orderItem.no_vat
					? 0
					: vat;

			const grossSales = bigMath.sub(
				orderItemOption.subtotal,
				orderItemOption.subtotal_addons.offer.amount,
				orderItemOption.subtotal_addons.discount.amount,
			);
			const addedVatScRate = bigMath.add(
				vatPercentage,
				orderItemOption.original_price_addons.service_charge.percentage,
			);
			const vatAmount = new Big(
				new Big(
					new Big(grossSales).sub(
						new Big(grossSales).div(new Big(1).add(addedVatScRate)),
					),
				).mul(vatPercentage),
			)
				.div(addedVatScRate || 1)
				.round(2)
				.toNumber();

			return {
				...orderItemOption,
				subtotal_addons: {
					...orderItemOption.subtotal_addons,
					vat: {
						is_included: isIncluded,
						percentage: vatPercentage,
						amount: vatAmount,
					},
				},
			};
		}),
	}));

	const vat_amount = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal_addons.vat.amount),
		0,
	);

	return calculateOrder({ ...order, items: orderItems, subtotal, vat_amount })
		.$order;
};

const applyVatToOrderGroup = <T>({
	orders,
	vat,
	skipPaidCheck = false,
}: {
	orders: (OrderLike & T)[];
	vat: number;
	skipPaidCheck?: boolean;
}) => {
	return orders
		.filter((o) => (skipPaidCheck ? true : $isOrderUnpaid(o)))
		.map((order) => applyVatToOrder({ order, vat }));
};

const calculateOrder = <T>(order: OrderLike & T) => {
	let orderItems = order.items.map($getItemMetadata).map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) =>
			$getOptionMetadata(orderItemOption, orderItem),
		),
	}));
	orderItems = orderItems.map((orderItem) => {
		const subtotal_addons = $getSubtotalAddons({
			item: orderItem,
			isCancelled: order.is_cancelled,
		});
		const { net_amount, gross_amount } = $getItemWithNetAndGrossAmount({
			...orderItem,
			subtotal_addons,
		});
		const cancelled_amount = 0;

		return {
			...orderItem,
			subtotal_addons,
			net_amount,
			gross_amount,
			cancelled_amount,
		};
	});
	orderItems = orderItems.map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) => {
			const subtotal_addons = $getOptionSubtotalAddons({
				option: orderItemOption,
				item: orderItem,
				isCancelled: order.is_cancelled,
			});
			const { net_amount, gross_amount } = $getOptionWithNetAndGrossAmount({
				...orderItemOption,
				subtotal_addons,
			});
			const cancelled_amount = 0;

			return {
				...orderItemOption,
				subtotal_addons,
				net_amount,
				gross_amount,
				cancelled_amount,
			};
		}),
	}));

	let cancelled_items = (order.cancelled_items ?? []).map($getItemMetadata);
	cancelled_items = cancelled_items.map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) =>
			$getOptionMetadata(orderItemOption, orderItem),
		),
	}));
	cancelled_items = cancelled_items.map((orderItem) => {
		const subtotal = 0;
		const subtotal_addons = $getSubtotalAddons({
			item: { ...orderItem, subtotal },
			isCancelled: true,
		});
		const net_amount = 0;
		const gross_amount = 0;
		const cancelled_amount = bigMath.sub(
			$getItemSubtotal({ ...orderItem, subtotal, net_amount, gross_amount }),
			$getItemTotalRawPriceAddons({
				...orderItem,
				subtotal,
				net_amount,
				gross_amount,
			}),
		); // to exclude any buried in amount so this is only `net` amount

		return {
			...orderItem,
			subtotal,
			subtotal_addons,
			net_amount,
			gross_amount,
			cancelled_amount,
		};
	});
	cancelled_items = cancelled_items.map((orderItem) => ({
		...orderItem,
		options: orderItem.options.map((orderItemOption) => {
			const subtotal = 0;
			const subtotal_addons = $getOptionSubtotalAddons({
				option: { ...orderItemOption, subtotal },
				item: orderItem,
				isCancelled: true,
			});
			const net_amount = 0;
			const gross_amount = 0;
			const cancelled_amount = bigMath.sub(
				$getOptionSubtotal(
					{ ...orderItemOption, subtotal, net_amount, gross_amount },
					orderItem,
				),
				$getOptionTotalRawPriceAddons({
					...orderItemOption,
					subtotal,
					net_amount,
					gross_amount,
				}),
			); // to exclude any buried in amount so this is only `net` amount

			return {
				...orderItemOption,
				subtotal,
				subtotal_addons,
				net_amount,
				gross_amount,
				cancelled_amount,
			};
		}),
	}));

	const max_prepare_time = orderItems.reduce(
		(pre, cur) => (pre > cur.prep_time ? pre : cur.prep_time),
		0,
	);
	const items_count = orderItems.reduce((pre, cur) => pre + cur.quantity, 0);
	const items_count_by_types = orderItems.reduce(
		(pre, cur) => {
			if (!cur.type) return { ...pre, unknown: pre.unknown + cur.quantity };

			if (!pre[cur.type]) return { ...pre, [cur.type]: cur.quantity };

			return { ...pre, [cur.type]: pre[cur.type] + cur.quantity };
		},
		{ unknown: 0 },
	);
	const prepped_count = orderItems.reduce((pre, cur) => {
		return cur.status === ORDER_ITEM_STATUS.delivered
			? pre + cur.quantity
			: pre;
	}, 0);

	const subtotal = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);
	const offer_amount = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal_addons.offer.amount),
		0,
	);
	const discount_amount = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal_addons.discount.amount),
		0,
	);
	const net_amount = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.net_amount),
		0,
	);
	const vat_amount = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal_addons.vat.amount),
		0,
	);
	const service_charge_amount = orderItems.reduce(
		(prev, cur) => bigMath.add(prev, cur.subtotal_addons.service_charge.amount),
		0,
	);
	const adjustment_amount = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal_addons.adjustment.amount),
		0,
	);
	const gross_amount = orderItems.reduce(
		(pre, cur) => bigMath.add(pre, cur.gross_amount),
		0,
	);
	const cancelled_amount = cancelled_items.reduce(
		(pre, cur) => bigMath.add(pre, cur.cancelled_amount),
		0,
	);

	const $order = {
		...order,
		items: orderItems,
		cancelled_items,
		max_prepare_time,
		items_count,
		items_count_by_types,
		prepped_count,
		subtotal,
		offer_amount,
		discount_amount,
		net_amount,
		vat_amount,
		service_charge_amount,
		adjustment_amount,
		grand_total: gross_amount,
		gross_amount,
		cancelled_amount,
	};

	return {
		$order,
		$isAllItemsWithVat:
			!!orderItems.length &&
			orderItems.every((item) => !!item.subtotal_addons.vat.amount),
		$isAllItemsWithServiceCharge:
			!!orderItems.length &&
			orderItems.every((item) => !!item.subtotal_addons.service_charge.amount),
		$isSomeItemsWithVat:
			!!orderItems.length &&
			orderItems.some((item) => !!item.subtotal_addons.vat.amount),
		$isSomeItemsWithServiceCharge:
			!!orderItems.length &&
			orderItems.some((item) => !!item.subtotal_addons.service_charge.amount),
		$roundedGrandTotal: toRoundedNumber(gross_amount),
		items: orderItems,
		cancelled_items,
		max_prepare_time,
		items_count,
		items_count_by_types,
		prepped_count,
		subtotal,
		offer_amount,
		discount_amount,
		net_amount,
		vat_amount,
		service_charge_amount,
		adjustment_amount,
		grand_total: gross_amount,
		gross_amount,
		cancelled_amount,
	};
};

const applyVenueAddonsToItem = <TObj, TVenue>({
	obj,
	venue,
}: {
	obj: { original_price: number } & TObj;
	venue: VenueLike & TVenue;
}) => {
	const { vat, is_vat_buried, service_charge, is_service_charge_buried } =
		venue;
	const { original_price } = obj;

	const vatAddOn = is_vat_buried ? bigMath.mul(original_price, vat) : 0;
	const serviceChargeAddOn = is_service_charge_buried
		? bigMath.mul(original_price, service_charge)
		: 0;
	const listedPrice = bigMath.add(original_price, vatAddOn, serviceChargeAddOn);

	const listed_price = listedPrice;
	const original_price_addons = {
		vat: { amount: vatAddOn, percentage: vat },
		service_charge: { amount: serviceChargeAddOn, percentage: service_charge },
	};

	return { ...obj, listed_price, original_price_addons };
};

const calculateOrderGroup = <T>(orders: (OrderLike & T)[]) => {
	return orders.reduce(
		(pre, cur) => {
			const isOrderUnpaid = $isOrderUnpaid(cur);
			const isOrderPaid = !isOrderUnpaid;

			return {
				all: $addOrderToOrderGroupCalculation(pre.all, cur),
				paid: isOrderPaid
					? $addOrderToOrderGroupCalculation(pre.paid, cur)
					: pre.paid,
				unpaid: isOrderUnpaid
					? $addOrderToOrderGroupCalculation(pre.unpaid, cur)
					: pre.unpaid,
			};
		},
		{
			all: baseOrderGroupValue,
			paid: baseOrderGroupValue,
			unpaid: baseOrderGroupValue,
		},
	);
};

const $getItemWithNetAndGrossAmount = <T>(orderItem: OrderItemLike & T) => {
	orderItem.subtotal_addons = {
		...BASE_SUBTOTAL_ADDONS,
		...orderItem.subtotal_addons,
	};

	const isVatIncluded =
		!!orderItem.original_price_addons.vat.percentage &&
		!!orderItem.original_price_addons.vat.amount;
	const isServiceChargeIncluded =
		!!orderItem.original_price_addons.service_charge.percentage &&
		!!orderItem.original_price_addons.service_charge.amount;

	let [gross_amount, net_amount] = [0, 0];

	if (isVatIncluded || isServiceChargeIncluded) {
		gross_amount = bigMath.add(
			bigMath.sub(
				orderItem.subtotal,
				orderItem.subtotal_addons.offer.amount,
				orderItem.subtotal_addons.discount.amount,
			),
			orderItem.subtotal_addons.adjustment.amount,
		);
		net_amount = bigMath.sub(
			gross_amount,
			orderItem.subtotal_addons.vat.amount,
			orderItem.subtotal_addons.service_charge.amount,
		);
	} else {
		net_amount = bigMath.sub(
			orderItem.subtotal,
			$getItemTotalRawPriceAddons(orderItem),
			orderItem.subtotal_addons.offer.amount,
			orderItem.subtotal_addons.discount.amount,
		);

		gross_amount = bigMath.add(
			net_amount,
			orderItem.subtotal_addons.vat.amount,
			orderItem.subtotal_addons.service_charge.amount,
			orderItem.subtotal_addons.adjustment.amount,
		);
	}

	return { ...orderItem, net_amount, gross_amount };
};

const $getOptionWithNetAndGrossAmount = <T>(
	orderItemOption: OrderItemOptionLike & T,
) => {
	orderItemOption.subtotal_addons = {
		...BASE_SUBTOTAL_ADDONS,
		...orderItemOption.subtotal_addons,
	};

	const isVatIncluded =
		!!orderItemOption.original_price_addons.vat.percentage &&
		!!orderItemOption.original_price_addons.vat.amount;
	const isServiceChargeIncluded =
		!!orderItemOption.original_price_addons.service_charge.percentage &&
		!!orderItemOption.original_price_addons.service_charge.amount;

	let [gross_amount, net_amount] = [0, 0];

	if (isVatIncluded || isServiceChargeIncluded) {
		gross_amount = bigMath.add(
			bigMath.sub(
				orderItemOption.subtotal,
				orderItemOption.subtotal_addons.offer.amount,
				orderItemOption.subtotal_addons.discount.amount,
			),
			orderItemOption.subtotal_addons.adjustment.amount,
		);
		net_amount = bigMath.sub(
			gross_amount,
			orderItemOption.subtotal_addons.vat.amount,
			orderItemOption.subtotal_addons.service_charge.amount,
		);
	} else {
		net_amount = bigMath.sub(
			orderItemOption.subtotal,
			$getOptionTotalRawPriceAddons(orderItemOption),
			orderItemOption.subtotal_addons.offer.amount,
			orderItemOption.subtotal_addons.discount.amount,
		);

		gross_amount = bigMath.add(
			net_amount,
			orderItemOption.subtotal_addons.vat.amount,
			orderItemOption.subtotal_addons.service_charge.amount,
			orderItemOption.subtotal_addons.adjustment.amount,
		);
	}

	return { ...orderItemOption, net_amount, gross_amount };
};

/**
 * paymentTypes should have the same currencies
 * this should be used before the payment is made
 * for after payment, use `isReceiptPaymentTypesValid`
 * */
const isPaymentTypesValid = <TPayment, TOrder>(
	paymentTypes: (PaymentType & TPayment)[],
	unpaidOrders: (OrderLike & TOrder)[],
	extraValidationFlags?: { is_payment_rounding_enabled?: boolean },
) => {
	if (
		paymentTypes.some(
			(p) => !p.code || !p.payment_type || isUndefinedOrNull(p.amount),
		)
	)
		return false;

	const { all } = calculateOrderGroup(unpaidOrders);
	const totalReceivedPayments = paymentTypes.reduce(
		(pre, cur) => bigMath.add(pre, cur.amount),
		0,
	);

	if (extraValidationFlags?.is_payment_rounding_enabled) {
		return toRoundedNumber(all.gross_amount) === totalReceivedPayments;
	}

	return all.gross_amount === totalReceivedPayments;
};

/**
 * paymentTypes should have the same currencies
 * this should be used after the payment is made
 * for before payment, use `isPaymentTypesValid`
 * */
const isReceiptPaymentTypesValid = <TPayment, TOrder>(
	receipt: {
		payment_types: (PaymentType & TPayment)[];
		rounding_difference_amount: number;
	},
	unpaidOrders: (OrderLike & TOrder)[],
) => {
	if (
		receipt.payment_types.some(
			(p) => !p.code || !p.payment_type || isUndefinedOrNull(p.amount),
		)
	)
		return false;

	const { all } = calculateOrderGroup(unpaidOrders);
	const totalReceivedPayments = receipt.payment_types.reduce(
		(pre, cur) => bigMath.add(pre, cur.amount),
		0,
	);

	return (
		bigMath.add(all.gross_amount, receipt.rounding_difference_amount) ===
		totalReceivedPayments
	);
};

/**
 * Rounds a number (or string convertible to a number) to the nearest whole number if it's 0.5 or more.
 */
const toRoundedNumber = (num: BigSource) =>
	new Big(num).toNumber() < 0.5
		? new Big(num).toNumber()
		: new Big(num).round().toNumber();

const getRoundingDifference = <T>(orders: (OrderLike & T)[]) => {
	const { all } = calculateOrderGroup(orders);

	return bigMath.sub(toRoundedNumber(all.gross_amount), all.gross_amount);
};

const getOffersUsedFromOrders = <T>(orders: (OrderLike & T)[]) => {
	const offersUsedDictionary: Record<
		string,
		{ offer: MongoDocument; amount: number }
	> = {};

	for (const i of orders.map((o) => o.items).flat()) {
		if (i.subtotal_addons?.offer.amount && i.subtotal_addons.offer.metadata) {
			const key = (i.subtotal_addons.offer.metadata as Offer)._id;

			if (offersUsedDictionary[key]) {
				offersUsedDictionary[key].amount = bigMath.add(
					offersUsedDictionary[key].amount,
					i.subtotal_addons?.offer?.amount,
				);
			} else {
				offersUsedDictionary[key] = {
					offer: i.subtotal_addons.offer.metadata,
					amount: i.subtotal_addons.offer.amount,
				};
			}
		}
	}

	return Object.values(offersUsedDictionary);
};

const getCustomersFromOrders = (orders: { customer?: MongoDocument }[]) => {
	const customersDictionary: Record<string, MongoDocument> = {};

	for (const o of orders) {
		if (o.customer && !customersDictionary[o.customer._id])
			customersDictionary[o.customer._id] = o.customer;
	}

	return Object.values(customersDictionary);
};

export {
	applyAdjustmentToItem,
	applyAdjustmentToOrder,
	applyAdjustmentToOrderGroup,
	applyDiscountToItem,
	applyDiscountToOrder,
	applyDiscountToOrderGroup,
	applyNonReceiptOfferToOrder,
	applyNonReceiptOfferToOrderGroup,
	applyReceiptOfferToOrder,
	applyReceiptOfferToOrderGroup,
	applyServiceChargeToOrder,
	applyServiceChargeToOrderGroup,
	applyVatToOrder,
	applyVatToOrderGroup,
	applyVenueAddonsToItem,
	calculateOrder,
	calculateOrderGroup,
	getCustomersFromOrders,
	$getItemWithNetAndGrossAmount as getItemWithNetAndGrossAmount,
	getOffersUsedFromOrders,
	getRoundingDifference,
	$getSubtotalAddons as getSubtotalAddons,
	isPaymentTypesValid,
	isReceiptPaymentTypesValid,
	toRoundedNumber,
};
export type { OrderItemLike, OrderItemOptionLike, OrderLike };

const $getOptionServingQuantity = (
	option: OrderItemOptionLike,
	item: OrderItemLike,
) => bigMath.mul(option.quantity, item.quantity);

const $getOptionSubtotal = (option: OrderItemOptionLike, item: OrderItemLike) =>
	bigMath.mul(option.listed_price, $getOptionServingQuantity(option, item));

const $getOptionMetadata = <TOption, TItem>(
	option: OrderItemOptionLike & TOption,
	item: OrderItemLike & TItem,
) => ({
	net_amount: 0,
	gross_amount: 0,
	cancelled_amount: 0,
	...option,
	serving_quantity: $getOptionServingQuantity(option, item),
	subtotal: $getOptionSubtotal(option, item),
	subtotal_addons: {
		...BASE_SUBTOTAL_ADDONS,
		...option.subtotal_addons,
	},
});

const $getItemServingQuantity = (item: OrderItemLike) =>
	bigMath.add(item.quantity, bigMath.mul(item.quantity, item.extra_quantity));

const $getItemMetadata = <T>(item: OrderItemLike & T) => ({
	net_amount: 0,
	gross_amount: 0,
	cancelled_amount: 0,
	...item,
	serving_quantity: $getItemServingQuantity(item),
	unit_price: $getItemUnitPrice(item),
	subtotal: $getItemSubtotal(item),
	subtotal_addons: {
		...BASE_SUBTOTAL_ADDONS,
		...item.subtotal_addons,
	},
});

const $isOrderUnpaid = <T>(order: OrderLike & T) =>
	!order.is_paid && !order.is_cancelled;

const $getSubtotalAddons = ({
	item,
	isCancelled,
}: {
	item: OrderItemLike;
	isCancelled: boolean;
}) => {
	if (isCancelled) return BASE_SUBTOTAL_ADDONS;

	item.subtotal_addons = {
		...BASE_SUBTOTAL_ADDONS,
		...item.subtotal_addons,
	};
	const { original_price_addons, subtotal, subtotal_addons } = item;

	const newOfferMetadata = subtotal_addons.offer.metadata;
	const newOfferAmount = isReceiptOffer(newOfferMetadata)
		? subtotal_addons.offer.amount
		: getAppliedOfferByItems([item], newOfferMetadata).offerAmount;

	const newDiscountIsDivided = subtotal_addons.discount.is_divided;
	const newDiscountType = subtotal_addons.discount.type;
	const newDiscountValue = subtotal_addons.discount.value;
	const newDiscountAmount =
		newDiscountType === DISCOUNT_TYPE.percentage || !newDiscountIsDivided
			? getDiscountAmount(subtotal, {
					type: newDiscountType,
					value: newDiscountValue,
				}).discountAmount
			: subtotal_addons.discount.amount;

	const newVatIsIncluded =
		!!original_price_addons.vat.percentage &&
		!!original_price_addons.vat.amount;
	const newVatPercentage = newVatIsIncluded
		? original_price_addons.vat.percentage
		: subtotal_addons.vat.percentage;
	const newServiceChargeIsIncluded =
		!!original_price_addons.service_charge.percentage &&
		!!original_price_addons.service_charge.amount;
	const newServiceChargePercentage = newServiceChargeIsIncluded
		? original_price_addons.service_charge.percentage
		: subtotal_addons.service_charge.percentage;

	let [newVatAmount, newServiceChargeAmount] = [0, 0];
	const grossSales = bigMath.sub(
		subtotal,
		subtotal_addons.offer.amount,
		subtotal_addons.discount.amount,
	);
	const addedVatScRate = bigMath.add(
		newVatPercentage,
		newServiceChargePercentage,
	);
	newVatAmount = newVatIsIncluded
		? new Big(
				new Big(
					new Big(grossSales).sub(
						new Big(grossSales).div(new Big(1).add(addedVatScRate)),
					),
				).mul(newVatPercentage),
			)
				.div(addedVatScRate || 1)
				.round(2)
				.toNumber()
		: bigMath.mul(
				bigMath.sub(
					subtotal,
					$getItemTotalRawPriceAddons(item),
					subtotal_addons.offer.amount,
					subtotal_addons.discount.amount,
				),
				newVatPercentage,
			);
	newServiceChargeAmount = newServiceChargeIsIncluded
		? new Big(
				new Big(
					new Big(grossSales).sub(
						new Big(grossSales).div(new Big(1).add(addedVatScRate)),
					),
				).mul(newServiceChargePercentage),
			)
				.div(addedVatScRate || 1)
				.round(2)
				.toNumber()
		: bigMath.mul(
				bigMath.sub(
					subtotal,
					$getItemTotalRawPriceAddons(item),
					subtotal_addons.offer.amount,
					subtotal_addons.discount.amount,
				),
				newServiceChargePercentage,
			);

	return {
		offer: {
			amount: newOfferAmount,
			metadata: newOfferAmount ? newOfferMetadata : undefined,
		},
		discount: {
			is_divided: newDiscountIsDivided,
			type: newDiscountType,
			value: newDiscountValue,
			amount: newDiscountAmount,
		},
		vat: {
			is_included: newVatIsIncluded,
			percentage: newVatPercentage,
			amount: newVatAmount,
		},
		service_charge: {
			is_included: newServiceChargeIsIncluded,
			percentage: newServiceChargePercentage,
			amount: newServiceChargeAmount,
		},
		adjustment: {
			amount: subtotal_addons.adjustment.amount,
		},
	};
};

const $getOptionSubtotalAddons = ({
	option,
	item,
	isCancelled,
}: {
	option: OrderItemOptionLike;
	item: OrderItemLike;
	isCancelled: boolean;
}) => {
	if (isCancelled) return BASE_SUBTOTAL_ADDONS;

	const orderItem = $getItemMetadata(item);
	const orderItemOption = $getOptionMetadata(option, orderItem);

	const { subtotal: itemSubtotal, subtotal_addons: itemSubtotalAddons } =
		orderItem;
	const {
		original_price_addons: optionOriginalPriceAddons,
		subtotal: optionSubtotal,
		subtotal_addons: optionSubtotalAddons,
	} = orderItemOption;

	const optionPriceRatioToItem = bigMath.div(optionSubtotal, itemSubtotal);

	const newOfferMetadata = itemSubtotalAddons.offer.metadata;
	const itemOfferAmount = itemSubtotalAddons.offer.amount;
	const newOfferAmount = bigMath.mul(optionPriceRatioToItem, itemOfferAmount);

	const newDiscountIsDivided = true;
	const newDiscountType = itemSubtotalAddons.discount.type;
	const newDiscountValue = itemSubtotalAddons.discount.value;
	const newDiscountAmount = bigMath.mul(
		optionPriceRatioToItem,
		itemSubtotalAddons.discount.amount,
	);

	const newAdjustmentAmount = bigMath.mul(
		optionPriceRatioToItem,
		itemSubtotalAddons.adjustment.amount,
	);

	const newVatIsIncluded =
		!!optionOriginalPriceAddons.vat.percentage &&
		!!optionOriginalPriceAddons.vat.amount;
	const newVatPercentage = newVatIsIncluded
		? optionOriginalPriceAddons.vat.percentage
		: optionSubtotalAddons.vat.percentage;
	const newServiceChargeIsIncluded =
		!!optionOriginalPriceAddons.service_charge.percentage &&
		!!optionOriginalPriceAddons.service_charge.amount;
	const newServiceChargePercentage = newServiceChargeIsIncluded
		? optionOriginalPriceAddons.service_charge.percentage
		: optionSubtotalAddons.service_charge.percentage;

	let [newVatAmount, newServiceChargeAmount] = [0, 0];
	const grossSales = bigMath.sub(
		optionSubtotal,
		optionSubtotalAddons.offer.amount,
		optionSubtotalAddons.discount.amount,
	);
	const addedVatScRate = bigMath.add(
		newVatPercentage,
		newServiceChargePercentage,
	);
	newVatAmount = newVatIsIncluded
		? new Big(
				new Big(
					new Big(grossSales).sub(
						new Big(grossSales).div(new Big(1).add(addedVatScRate)),
					),
				).mul(newVatPercentage),
			)
				.div(addedVatScRate || 1)
				.round(2)
				.toNumber()
		: bigMath.mul(
				bigMath.sub(
					optionSubtotal,
					$getOptionTotalRawPriceAddons(orderItemOption),
					optionSubtotalAddons.offer.amount,
					optionSubtotalAddons.discount.amount,
				),
				newVatPercentage,
			);
	newServiceChargeAmount = newServiceChargeIsIncluded
		? new Big(
				new Big(
					new Big(grossSales).sub(
						new Big(grossSales).div(new Big(1).add(addedVatScRate)),
					),
				).mul(newServiceChargePercentage),
			)
				.div(addedVatScRate || 1)
				.round(2)
				.toNumber()
		: bigMath.mul(
				bigMath.sub(
					optionSubtotal,
					$getOptionTotalRawPriceAddons(orderItemOption),
					optionSubtotalAddons.offer.amount,
					optionSubtotalAddons.discount.amount,
				),
				newServiceChargePercentage,
			);

	return {
		offer: {
			amount: newOfferAmount,
			metadata: newOfferAmount ? newOfferMetadata : undefined,
		},
		discount: {
			is_divided: newDiscountIsDivided,
			type: newDiscountType,
			value: newDiscountValue,
			amount: newDiscountAmount,
		},
		vat: {
			is_included: newVatIsIncluded,
			percentage: newVatPercentage,
			amount: newVatAmount,
		},
		service_charge: {
			is_included: newServiceChargeIsIncluded,
			percentage: newServiceChargePercentage,
			amount: newServiceChargeAmount,
		},
		adjustment: {
			amount: newAdjustmentAmount,
		},
	};
};

function $getItemSubtotal(item: OrderItemLike) {
	return new Big($getItemUnitPrice(item))
		.times(item.quantity || 0)
		.round(2)
		.toNumber();
}

function $getItemUnitPrice(item: OrderItemLike) {
	return new Big(item.listed_price || 0)
		.add(
			item.options.reduce<BigSource>(
				(pre, cur) =>
					new Big(pre).add(
						new Big(cur.listed_price || 0).mul(cur.quantity || 1),
					),
				0,
			),
		)
		.round(2)
		.toNumber();
}

const $getItemTotalRawPriceAddons = (item: OrderItemLike) => {
	return new Big(item.original_price_addons.vat.amount)
		.add(item.original_price_addons.service_charge.amount)
		.add(
			item.options.reduce<BigSource>(
				(pre, cur) =>
					new Big(pre)
						.add(cur.original_price_addons.vat.amount)
						.add(cur.original_price_addons.service_charge.amount),
				0,
			),
		)
		.times(item.quantity)
		.round(2)
		.toNumber();
};

function $getOptionTotalRawPriceAddons(option: OrderItemOptionLike) {
	return new Big(option.original_price_addons.vat.amount)
		.add(option.original_price_addons.service_charge.amount)
		.times(option.serving_quantity)
		.round(2)
		.toNumber();
}

const $addOrderToOrderGroupCalculation = <T>(group, order: OrderLike & T) => {
	const {
		$order,
		$isAllItemsWithVat,
		$isAllItemsWithServiceCharge,
		$isSomeItemsWithVat,
		$isSomeItemsWithServiceCharge,
		items,
		cancelled_items,
		max_prepare_time,
		items_count,
		items_count_by_types,
		prepped_count,
		subtotal,
		offer_amount,
		discount_amount,
		net_amount,
		vat_amount,
		service_charge_amount,
		adjustment_amount,
		grand_total,
		gross_amount,
		cancelled_amount,
	} = calculateOrder(order);

	return {
		$orders: group.$orders.concat($order),
		$isAllItemsWithVat: group.$isAllItemsWithVat && $isAllItemsWithVat,
		$isAllItemsWithServiceCharge:
			group.$isAllItemsWithServiceCharge && $isAllItemsWithServiceCharge,
		$isSomeItemsWithVat: group.$isSomeItemsWithVat || $isSomeItemsWithVat,
		$isSomeItemsWithServiceCharge:
			group.$isSomeItemsWithServiceCharge || $isSomeItemsWithServiceCharge,
		$roundedGrandTotal: toRoundedNumber(
			bigMath.add(group.grand_total, grand_total),
		),
		items: group.items.concat(items),
		cancelled_items: group.items.concat(cancelled_items),
		max_prepare_time:
			group.max_prepare_time < max_prepare_time
				? max_prepare_time
				: group.max_prepare_time,
		items_count: bigMath.add(group.items_count, items_count),
		items_count_by_types: $mergeObj(
			group.items_count_by_types,
			items_count_by_types,
		),
		prepped_count: bigMath.add(group.prepped_count, prepped_count),
		subtotal: bigMath.add(group.subtotal, subtotal),
		discount_amount: bigMath.add(group.discount_amount, discount_amount),
		offer_amount: bigMath.add(group.offer_amount, offer_amount),
		net_amount: bigMath.add(group.net_amount, net_amount),
		vat_amount: bigMath.add(group.vat_amount, vat_amount),
		service_charge_amount: bigMath.add(
			group.service_charge_amount,
			service_charge_amount,
		),
		adjustment_amount: bigMath.add(group.adjustment_amount, adjustment_amount),
		grand_total: bigMath.add(group.grand_total, grand_total),
		gross_amount: bigMath.add(group.gross_amount, gross_amount),
		cancelled_amount: bigMath.add(group.cancelled_amount, cancelled_amount),
	};
};

function $mergeObj(a, b) {
	const merged = { ...a };

	for (const key in b) {
		if (key in merged) {
			merged[key] += b[key];
		} else {
			merged[key] = b[key];
		}
	}
	return merged;
}

const baseOrderGroupValue = {
	$orders: [] as OrderLike[],
	$isAllItemsWithVat: true,
	$isAllItemsWithServiceCharge: true,
	$isSomeItemsWithVat: false,
	$isSomeItemsWithServiceCharge: false,
	$roundedGrandTotal: 0,
	items: [] as OrderLike["items"][],
	cancelled_items: [] as OrderLike["cancelled_items"][],
	max_prepare_time: 0,
	items_count: 0,
	items_count_by_types: {} as Record<string, number>,
	prepped_count: 0,
	subtotal: 0,
	discount_amount: 0,
	offer_amount: 0,
	net_amount: 0,
	vat_amount: 0,
	service_charge_amount: 0,
	adjustment_amount: 0,
	grand_total: 0,
	gross_amount: 0,
	cancelled_amount: 0,
};
